<template>
  <transition name="fade">
    <v-toolbar
      v-if="selected.length > 0"
      class="my-4 px-1 primary"
      dense
      rounded
      dark
    >
      <v-toolbar-title style="min-width: 400px">
        {{ selected.length }} Selecionados
      </v-toolbar-title>

      <v-spacer />

      <component
        :is="currentComponent"
        :selected-items="selected"
        @modalClicked="modalClicked"
        @clearSelected="clearSelected"
      />
    </v-toolbar>
  </transition>
</template>

<script>
    import { massAction } from '@/main.js'
    export default {
        components: {
            UsersMassAction: () => import('@/components/people/users/UsersMassAction'),
            SellersMassAction: () => import('@/components/people/sellers/SellersMassAction'),
            AgenciesMassAction: () => import('@/components/people/agencies/AgenciesMassAction'),
            CompaniesMassAction: () => import('@/components/people/companies/CompaniesMassAction'),
            ClientsMassAction: () => import('@/components/people/clients/ClientsMassAction'),
            ProfilesMassAction: () => import('@/components/people/profiles/ProfilesMassAction'),
            ProductsMassAction: () => import('@/components/stock/products/ProductsMassAction'),
            CategoriesMassAction: () => import('@/components/stock/categories/CategoriesMassAction'),
            NcmMassAction:() => import('@/components/stock/ncm/NcmMassAction'),
            ColorsMassAction:() => import('@/components/stock/colors/ColorsMassAction'),
            VendorsMassAction:() => import('@/components/stock/vendors/VendorsMassAction'),
            ProductVendorsMassAction:() => import('@/components/stock/vendors/ProductVendorsMassAction'),
            PrintMassAction:() => import('@/components/stock/print/PrintMassAction'),
            CompositionMassAction:() => import('@/components/stock/composition/CompositionMassAction'),
            FactorMassAction:() => import('@/components/stock/factor/FactorMassAction'),
            BudgetsMassAction:() => import('@/components/comercial/budgets/BudgetsMassAction'),
            OrdersMassAction: () => import('@/components/comercial/orders/OrdersMassAction.vue')
        },
        props: {
            selected: Array,
            currentComponent: null
        },
        methods: {
            modalClicked() {
                massAction.action()
            },
            clearSelected() {
                this.$emit('clearSelected');
            }
        }
    }
</script>

<style lang="scss">
    .fade-enter-active, .fade-leave-active {
        opacity: 1;
        transition: opacity .2s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
        transition: opacity .2s;
    }
</style>